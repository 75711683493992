/*
 * @Autor: oops.liu
 */
import Crypto from "crypto-js";
// 密钥 16 位
const originKey = "0123456789abcdef";
// 初始向量 initial vector 16 位
const originIv = "0123456789abcdef";

const key = Crypto.enc.Utf8.parse(originKey);
const iv = Crypto.enc.Utf8.parse(originIv);

const mode = {
  // 偏移量
  iv: iv,
  // 模式
  mode: Crypto.mode.CBC,
  // 填充
  padding: Crypto.pad.Pkcs7,
};
/**
 * 加密
 */
export function encrypt(text) {
  const encryptText = Crypto.AES.encrypt(text, key, mode);
  return encryptText.toString();
}
/**
 * 解密
 */
export function decrypt(text) {
  const encryptText = Crypto.AES.decrypt(text, key, mode);
  return Crypto.enc.Utf8.stringify(encryptText);
}
