import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import app from "./app";
import refund from "./refund";
import seller from "./seller";
import user from "./user";
import agreement from './agreement'
import goods from './goods'
import active from './active'
export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    app,
    refund,
    seller,
    user,
    agreement,
    goods,
    active
  },
});
