/*
 * @Autor: oops.liu
 */
import request from "axios";
import LocalCache from "@u/cache";
import { Toast } from "vant";
var tokens=''
// ==================================================
// var u = navigator.userAgent;
// var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //android
// var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios
// if (isAndroid) {
//   window.android.getToken("get"); // 向android传参
// } 
// if(isiOS) {
//   window.webkit.messageHandlers.getToken.postMessage("getToken"); // 向ios传参
// }
  
//     //安卓调用js  获取token
//     window.jsGetToken=(msg)=>{
//       tokens=msg
//       // sessionStorage.setItem("token", msg)
//     }
// ==================================================
const head = {
  //请求头
  "X-Requested-With": "XMLHttpRequest",
  "Content-Type": "application/json; charset=utf-8",
  'Authorization':tokens
};

const tokenKey = "Authorization";

let isdebug = process.env.NODE_ENV === "development";
// 保存toast实例
let toast;
// 默认是否展示toast

const axios = request.create({
  baseURL: isdebug ? "/api" : "/api",
  // baseURL:  "http://xdys.share.fypay-xm.com/api",
  timeout: 10000,
  headers: head,
});

axios.interceptors.request.use(
  (config) => {
    let showLoading;
    if (Object.prototype.hasOwnProperty.call(config, "showLoading")) {
      showLoading = !!config.showLoading;
    } else {
      showLoading = true;
    }
    if (showLoading) {
      toast = Toast.loading({
        message: "加载中...",
        forbidClick: true,
      });
    }

    // config.headers["Content-Type"] = "application/json";
    config.headers["tenantid"] = 1;
    config.headers["sourceType"] = 3;
    // const token = LocalCache.getCache(tokenKey);
    // const token = sessionStorage.getItem("token")
    // const token = 'MzZlMjJkMGItNjhmNS00YjhiLTgwNTEtMmJhN2RiZDQ5MThh'
    const token = tokens
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    } else {
      console.log("error:没有获取到token");
    }
    return config;
  },
  (error) => {
    console.log("前置错误", error);
  }
);
axios.interceptors.response.use(
  (response) => {
    toast.clear();
    const result = response.data;
    return result;
  },
  (error) => {
    toast.clear();
    console.log("后置错误", error);
  }
);
export { tokenKey, axios };
