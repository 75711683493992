/*
 * @Autor: oops.liu
 */
import { getInfo } from "@/axios";
const state = {};
const mutations = {};
const actions = {
  // 获取问答列表
  async getQAList() {
    const result = await getInfo("/mall-shop/api/qa/page");
    return result.data.records
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
