import Vue from "vue";
import {
  Button,
  Tabbar,
  TabbarItem,
  NavBar,
  Search,
  Tabs,
  Tab,
  Swipe,
  SwipeItem,
  DropdownMenu,
  DropdownItem,
  NoticeBar,
  Sidebar,
  SidebarItem,
  Dialog,
  Field,
  Toast,
  Badge,
  Collapse,
  CollapseItem,
  Steps,
  Step,
  Popup,
  Icon,
  CountDown,
  Progress,
  Empty,
  Cell,
  GoodsAction,
  GoodsActionIcon,
  GoodsActionButton,
  Sku,
  Popover,
} from "vant";

Vue.use(Button);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(NavBar);
Vue.use(Search);
Vue.use(Search);
Vue.use(Tabs);
Vue.use(Tab);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(NoticeBar);
Vue.use(Sidebar);
Vue.use(SidebarItem);
Vue.use(Dialog);
Vue.use(Field);
Vue.use(Toast);
Vue.use(Badge);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Steps);
Vue.use(Step);
Vue.use(Popup);
Vue.use(Icon);
Vue.use(CountDown);
Vue.use(Progress);
Vue.use(Empty);
Vue.use(Cell);
Vue.use(GoodsAction);
Vue.use(GoodsActionIcon);
Vue.use(GoodsActionButton);
Vue.use(Sku);
Vue.use(Popover);
