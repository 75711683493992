/*
 * @Autor: oops.liu
 */

import { axios } from "./base.js";

export default function postInfo(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, params)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
