/*
 * @Autor: oops.liu
活动页面接口
 */
import { getInfo } from "@/axios";
const seller = {
  namespaced: true,
  state() {
    return {};
  },
  mutations: {},
  actions: {
    // 获取活动商家信息
    async getseller({ state, commit }, sellerId) {
      const result = await getInfo("/mall-shop/api/shop/act/" + sellerId);

      return result.data || {};
    },
    // 商家分类列表
    async getCategory({ state, commit }, payload) {
      const result = await getInfo("/mall-shop/api/shop/scope");
      const list = result.data || [];
      return list;
    },
    // 根据分类获取商品列表
    async getgoodsList({ state, commit }, queryCriteria) {
      const result = await getInfo("/mall-shop/api/shop/actshop", { ...queryCriteria });
      const data = result.data || {};
      return data;
    },
    // 商城一级分类列表
    async getMallFirst({ state, commit }, queryCriteria) {
      const result = await getInfo("/mall-goods/api/homecat/first");
      const data = result.data || {};
      return data.records || [];
    },
    async getMallGoodsToMallFirst({ state, commit }, queryCriteria) {
      const result = await getInfo("/mall-goods/api/goodsSpu/page", { ...queryCriteria });
      const data = result.data || {};
      return data || {};
    },
  },
};

export default seller;
