import { getInfo ,postInfo} from "@/axios";
const seller = {
  namespaced: true,
  state() {
    return {
      id: "",
      sellerInfo: {},
      goodsList: [],
      couponList: [],
      testList: [],
      categoryList: [],
      shopId: "",
      msglength: 0,
    };
  },
  mutations: {
    setSllerInfo(state, info) {
      state.sellerInfo = info;
    },
    setGoodsList(state, list) {
      state.goodsList = list;
    },
    setId(state, id) {
      state.id = id;
    },
    setCouponList(state, list) {
      state.couponList = list;
    },
    setTest(state, list) {
      state.testList = list;
    },
    setCategoryList(state, list) {
      state.categoryList = list;
    },
    setShopId(state, shopId) {
      state.shopId = shopId;
    },
    setMsglength(state, len) {
      state.msglength = len;
    },
  },
  actions: {
    async loadSeller({ state, commit }, sellerId) {
      const result = await getInfo("/mall-shop/api/shop/" + sellerId, null, { showLoading: false });
      const sllerInfo = result.data;
      commit("setSllerInfo", sllerInfo);
      return result;
    },
    // 获取商品列表(包括排序)
    async loadGoodsList({ state, commit }, info) {
      const result = await getInfo("/mall-goods/api/goodsSpu/page", info);
      commit("setTest", result.data.records);
      return result.data;
    },
    // 获取店铺优惠券
    // async loadAllCoupon({ state, commit }, info) {
    //   const result = await getInfo("/mall-goods/api/coupon/page", info);
    //   const data = result.data;
    //   commit("setCouponList", data.records);
    //   return data;
    // },
    // 获取店铺分类
    async loadGoodsCategory({ state, commit }, shopId) {
      const result = await getInfo("/mall-goods/api/goodscatshop/first/" + shopId);
      const data = result.data ? result.data : {};
      commit("setCategoryList", data);
      return data;
    },
    // 商品模糊搜索
    async searchGoodsCategory({ state, commit, rootState }, searchInfo) {
      const result = await getInfo("/mall-goods/api/goodsSpu/page", searchInfo);
      return result.data;
    },
    // 获取热搜词汇
    async hotWords({ state, commit, rootState }, searchInfo) {
      const result = await getInfo("/mall-shop/api/hotWord/page");

      return result.data.records || [];
    },
    async getSellerInfo({ state, commit, rootState }, shopId) {
      const result = await getInfo("/mall-shop/api/shop/" + shopId);
      return result.data;
    },
    // 关注店铺/api/shopInfo/ifFocusShop status	状态 1：关注 2：取消关注
    
    async ifFocusShop({ state, commit }, searchInfo) {
      const result = await getInfo("/mall-shop/api/shopInfo/ifFocusShop",searchInfo);
      return result.data;
    },
    // 获取消息总数
    async getMessageList({ state, commit, rootState }, shopId) {
      const result = await getInfo("/mall-user/api/userNotice/list");
      const msgList = result.data;
      let msglen = 0;
      for (const item of msgList) {
        if (item.count) {
          msglen += item.count;
        }
      }
      commit("setMsglength", msglen);
      return msglen;
    },
    // 获取优惠券列表
    async getCouponList({ state, commit, rootState }, shopId) {
      const result = await getInfo("/mall-goods/api/coupon/shop/page", {
        shopId: shopId,
        pageSize: 30,
      });
      const data = result.data || {};
      const list = data.records || [];
      commit("setCouponList", list);
      return list;
    },
    // 领取优惠券
    async getCoupon({ state, commit, rootState }, couponId) {
      const result = await postInfo("/mall-user/api/couponUser", {
        couponId: couponId,
      });
    },
  },
};

export default seller;
