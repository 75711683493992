/*
 * @Autor: oops.liu
 */
const staticPath = "YHYG_";
import { encrypt, decrypt } from "./crypto";
class LocalCache {
  setCache(key, value) {
    const val = encrypt(JSON.stringify(value));
    window.localStorage.setItem(staticPath + key, val);
  }

  getCache(key) {
    // obj => string => obj
    const value = window.localStorage.getItem(staticPath + key);
    if (value) {
      const val = decrypt(value);
      try {
        return JSON.parse(val);
      } catch {
        return val;
      }
    }
    return undefined;
  }

  deleteCache(key) {
    window.localStorage.removeItem(staticPath + key);
  }

  clearCache() {
    window.localStorage.clear();
  }
}

export default new LocalCache();
