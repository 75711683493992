import { getInfo } from "@/axios";
const goods = {
  namespaced: true,
  state() {
    return {};
  },
  mutations: {},
  actions: {
    async loadGoods({ state, commit }, goodsId) {
      // alert(goodsId)
      // 获取sku
      const skuResult = await getInfo("/mall-goods/api/goodsSpu/spuspec/tree", { spuId: goodsId }, { showLoading: false });
      const skuInfo = skuResult.data;
      // 获取商品详情
      const result = await getInfo("/mall-goods/api/goodsSpu/" + goodsId);
      const goodsInfo = result.data;
      // 获取商品保障
      const listEnsureResult = await getInfo("/mall-goods/api/goodsSpu/listEnsureBySpuId", { spuId: goodsId }, { showLoading: false });
   
      const listEnsureInfo = listEnsureResult.data
      return { goodsInfo, skuInfo ,listEnsureInfo};
    },
  },
};

export default goods;
