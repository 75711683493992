/*
 * @Autor: oops.liu
 */
import { getInfo } from "@/axios";
const user = {
  namespaced: true,
  state() {
    return {
      token: "",
      userInfo: {},
    };
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
    },
    setUserInfo(state, info) {
      state.userInfo = info;
    },
  },
  actions: {
    async loadSeller({ state, commit }, sellerId) {
      const result = await getInfo("/mall-shop/api/shop/" + sellerId, null, { showLoading: false });
      const sllerInfo = result.data;
      commit("setSllerInfo", sllerInfo);
      return result;
    },
  },
};

export default user;
