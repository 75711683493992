/*
 * @Autor: oops.liu
 */
import { getInfo } from "@/axios";
const agreement = {
  namespaced: true,
  state() {
    return {};
  },
  mutations: {},
  actions: {
    async load({ state, commit }, type) {
      // 获取商品详情
      const result = await getInfo("/mall-shop/api/protocol/details", type);
      const data = result.data;
      return data;
    },
  },
};

export default agreement;
