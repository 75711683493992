/*
 * @Autor: oops.liu
 */

import { axios } from "./base.js";

export default function getInfo(url, params, data) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, { params, headers: { ...data } })
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
