import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

Vue.config.productionTip = false;

import "./plugins/vant";

import vueScroller from "vue-scroller";
Vue.use(vueScroller);
// 消除延迟
// import fastClick from "fastclick";
// fastClick.attach(document.body);

import "./assets/css/toast.css";
// 解决ios的home金刚键代码
import directives from "./directives";
Object.keys(directives).forEach((item) => {
  Vue.directive(item, directives[item]);
});
import "vant/lib/index.less";
import "vant/lib/popover/style/less";

// import vconsole from "vconsole";
// const vc = new vconsole();

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
