import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "/",
    redirect: "home",
    component: () => import("views/seller_shop/index.vue"),
    children: [
      {
        path: "home",
        name: "home",
        component: () => import("views/seller_shop/home/index.vue"),
        meta: {
          title: "首页",
          order: 1,
        },
      },
      {
        path: "goods",
        name: "goods",
        component: () => import("views/seller_shop/goods/index.vue"),
        meta: {
          title: "商品",
          order: 2,
        },
      },
      {
        path: "category",
        name: "category",
        component: () => import("views/seller_shop/category/index.vue"),
        meta: {
          title: "分类",
          order: 3,
        },
      },
    ],
  },
  {
    path: "/goodsDetail/:id",
    name: "goodsDetail",
    props: true,
    component: () => import("views/goodsDetail/index.vue"),
    meta: {
      title: "商品详情",
    },
  },
  {
    path: "/search",
    name: "search",
    component: () => import("views/seller_shop/search/index.vue"),
    meta: {
      title: "搜索",
    },
  },
  {
    path: "/search_list",
    name: "search_list",
    component: () => import("views/seller_shop/search/search_list/index.vue"),
    meta: {
      title: "搜索",
    },
  },
  {
    path: "/seller/:shopId",
    name: "seller",
    component: () => import("views/seller/index.vue"),
    meta: {
      title: "店铺详情",
    },
  },
  // 店铺内
  {
    path: "/seller_verify/:shopId",
    name: "seller_verify",
    component: () => import("views/seller_shop/verify/index.vue"),
    meta: {
      title: "商家证照信息",
    },
  },
  // 店铺内
  {
    path: "/seller_idPhoto",
    name: "seller_idPhoto",
    component: () => import("views/seller_shop/verify/idPhoto/index.vue"),
    meta: {
      title: "证照信息",
    },
  },
  // 线下实体部分
  {
    path: "/verify/:shopId",
    name: "verify",
    component: () => import("views/verify/index.vue"),
    meta: {
      title: "商家证照信息",
    },
  },
  // 线下实体部分
  {
    path: "/idPhoto",
    name: "idPhoto",
    component: () => import("views/verify/idPhoto/index.vue"),
    meta: {
      title: "证照信息",
    },
  },

  {
    path: "/question",
    name: "question",
    component: () => import("views/question/index.vue"),
    meta: {
      title: "常见问题",
    },
  },
  {
    path: "/progress",
    name: "progress",
    component: () => import("views/refund/progress/index.vue"),
    meta: {
      title: "进度详情",
    },
  },
  {
    path: "/solution/:id",
    name: "solution",
    component: () => import("views/refund/solution/index.vue"),
    meta: {
      title: "查看解决方案",
    },
  },
  {
    path: "/brand",
    name: "brand",
    component: () => import("views/app_home_page/brand/index.vue"),
    meta: {
      title: "品牌专区",
    },
  },
  {
    path: "/exclusive/:shopId",
    name: "exclusive",
    component: () => import("views/app_home_page/brand/exclusive/index.vue"),
    meta: {
      title: "品牌专场",
    },
  },
  {
    path: "/new_products",
    name: "new_products",
    component: () => import("views/app_home_page/new_products/index.vue"),
    meta: {
      title: "新品上架",
    },
  },
  {
    path: "/goodOption",
    name: "goodOption",
    component: () => import("views/app_home_page/goodOption/index.vue"),
    meta: {
      title: "好物优选",
    },
  },
  {
    path: "/rich/:type",
    name: "rich",
    props: true,
    component: () => import("views/rich/index.vue"),
    meta: {
      title: "富文本",
    },
  },
  {
    path: "/agreement/:type",
    name: "agreement",
    props: true,
    component: () => import("views/agreement/index.vue"),
    meta: {
      title: "协议",
    },
  },
  {
    path: "*",
    name: "not_found",
    component: () => import("views/not_found/index.vue"),
    meta: {
      title: "404",
    },
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
});
router.beforeEach((to, from, next) => {
  let title = to.meta && to.meta.title;
  if (title) {
    document.title = title;
  }
  next();
});
export default router;
