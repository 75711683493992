/*
 * @Autor: oops.liu
 */
const state = {
  // 测试数据可删除
  goods:{
    name:'Elycra 连衣裙女装2021年夏季新款气质收腰显瘦法式Elycra 连衣裙女装2021年夏季新款气质收腰显瘦法式',
    cover:require("@/assets/img/b.jpg"),
    price:100
  }
}
const mutations = {}
const actions = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};